import React from "react";
import { Nav, Navbar } from "react-bootstrap";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Styles = styled.div`
  .navbar {
    background-color: #222;
  }
  e a,
  .navbar-brand,
  .navbar-nav .nav-link {
    color: white;

    &:hover {
      color: white;
    }
  }
`;

function NavigationBar() {
  return (
    <Styles>
      <Navbar fixed="top" expand="lg">
        <Navbar.Brand href="/" style={{ color: "white" }}>
          Youssef Ibrahim
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          style={{ color: "white !important", backgroundColor: "#c3edea" }}
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Item>
              <Link to="/" style={{ color: "white" }}>
                Home
              </Link>
            </Nav.Item>
            <Nav.Item className="burgerMenuItems">
              <Link to="/controls" style={{ color: "white" }}>
                Controls Engineering
              </Link>
            </Nav.Item>
            <Nav.Item className="burgerMenuItems">
              <Link to="/software" style={{ color: "white" }}>
                Software Development
              </Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Styles>
  );
}

export default NavigationBar;
