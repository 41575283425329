import React, { useState, useEffect } from "react";
import ControlsProjects from "./controlsComponents/controlsProjects";
import ControlsCertificates from "./controlsComponents/controlsCertificates";
import Footer from "../../components/Footer";

import { Button } from "react-bootstrap";

function Controls() {
  const storedValue = localStorage.getItem("controlsPage");

  const [controlsState, setControls] = useState(
    storedValue ? storedValue === "true" : true
  );

  function setProject() {
    setControls(true);
    localStorage.setItem("controlsPage", true);
  }

  function setCertificate() {
    localStorage.setItem("controlsPage", false);
    setControls(false);
  }

  const controlsLinks = [
    {
      title: "Conveyor Sensor Program",
      caption:
        "A program that senses the number of items on a conveyor belt and stops the conveyor after a specified number.",
      url: "",
      image: "plc1",
      description: "",
    },
    {
      title: "Garage door automator",
      caption:
        "A program that automatically opens a garage door, waits for a few seconds then closes the garage door.",
      url: "",
      image: "plc2",
      description: "",
    },
    {
      title: "Automatic Motor Starter",
      caption:
        "A program that sequentially starts a series of motors, then after a specified wait time, turns them off.",
      url: "",
      image: "plc3",
      description: "",
    },
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height: "300px",
        }}
      >
        <div className="pl-5 pr-5 pt-5 mt-5">
          <span
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <h2 className="section-heading text-uppercase">Controls</h2>
          </span>
          <p>
            This page allows you to see some of the ladder logic programs and
            projects that I have worked on.
          </p>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "500px !important",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Button
              onClick={setProject}
              variant={controlsState ? "info" : "outline-info"}
              style={{ width: "300px" }}
            >
              Projects
            </Button>
            <Button
              onClick={setCertificate}
              variant={!controlsState ? "warning" : "outline-warning"}
              style={{ width: "300px" }}
            >
              Experiences
            </Button>
          </div>
        </div>
      </div>

      {controlsState ? (
        <ControlsProjects controlsLinks={controlsLinks} />
      ) : (
        <ControlsCertificates />
      )}
      <Footer />
    </div>
  );
}

export default Controls;
