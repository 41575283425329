import React from "react";

function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-4">
            <span className="copyright">
              Copyright &copy; Youssef Ibrahim 2020
            </span>
          </div>
          <div className="col-md-4">
            <ul className="list-inline social-buttons">
              <li className="list-inline-item social-button-linkedin">
                <a
                  href="https://www.linkedin.com/in/youssefibrahim/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "00ACEE" }}
                >
                  <i className="fa fa-linkedin"></i>
                </a>
              </li>
              <li
                className="list-inline-item social-button-github"
                style={{ color: "" }}
              >
                <a
                  href="https://github.com/youssefibrahim1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-github"></i>
                </a>
              </li>
              <li
                className="list-inline-item social-button-email"
                style={{ color: "" }}
              >
                <a href="mailto:youssef9ibrahim@gmail.com">
                  <i className="fa fa-envelope"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
