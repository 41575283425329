import React from "react";

function SoftwareProjects({ softwareLinks }) {
  return (
    <section className="bg-light page-section" id="portfolio">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <h3 className="section-heading text-uppercase">Projects</h3>
            <h5 className="section-subheading text-muted mb-5">
              Some of the projects that I have worked on so far.
            </h5>
          </div>
        </div>
        <div className="row">
          {softwareLinks &&
            softwareLinks.map(
              ({ title, caption, image, url, description }, index) => (
                <div key={index} className="col-md-4 col-sm-6 portfolio-item">
                  <div className="portfolio-link">
                    <img
                      className="img-fluid"
                      src={require(`../softwareAssets/softwareProjects/${image}.png`)}
                      alt="portfolio_img"
                      style={{
                        height: "14em",
                        width: "100%",
                      }}
                    />
                  </div>

                  <div className="portfolio-caption">
                    <h4>{title}</h4>
                    <p className="text-muted">{caption}</p>
                    <a target="_blank" rel="noopener noreferrer" href={url}>
                      Go to Site
                    </a>
                    {/* <h6>{description}</h6> */}
                  </div>
                </div>
              )
            )}
        </div>
      </div>
    </section>
  );
}

export default SoftwareProjects;
