import React from "react";

function SoftwareCertificates() {
  return (
    <div>
      <section className="page-section" id="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="section-heading text-uppercase">
                Skills and Experiences
              </h2>
              <h3 className="section-subheading text-muted">
                A timeline of significant learning milestones on my software
                Journey.
              </h3>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <ul className="timeline">
                <li>
                  <div className="timeline-image">
                    <img
                      className="rounded-circle img-fluid"
                      src={require(`../softwareAssets/softwareCertificates/rbc.jpg`)}
                      alt=""
                    />
                  </div>
                  <div className="timeline-panel">
                    <div className="timeline-heading">
                      <h4>RBC Ventures </h4>
                      <h6 className="subheading">
                        Software Developer (April 2018- September 2019)
                      </h6>
                    </div>
                    <div className="timeline-body">
                      <p className="text-muted">
                        I worked for a year and a half as a full stack software
                        developer at RBC Ventures.
                      </p>
                    </div>
                  </div>
                </li>
                <li className="timeline-inverted">
                  <div className="timeline-image">
                    <img
                      className="rounded-circle img-fluid"
                      src={require(`../softwareAssets/softwareCertificates/fcc.jpg`)}
                      alt=""
                    />
                  </div>
                  <div className="timeline-panel">
                    <div className="timeline-heading">
                      <h4>FreeCodeCamp </h4>
                      <h6 className="subheading">
                        JavaScript Algorithms and Data Structures (July 2018)
                      </h6>
                    </div>
                    <div className="timeline-body">
                      <p className="text-muted">
                        I completed the JavaScript Algorithms and Data
                        Structures which took approximately 300 hours to
                        complete.
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="timeline-image">
                    <img
                      className="rounded-circle img-fluid"
                      src={require(`../softwareAssets/softwareCertificates/wherego.jpg`)}
                      alt=""
                    />
                  </div>
                  <div className="timeline-panel">
                    <div className="timeline-heading">
                      <h4>Wherego </h4>
                      <h6 className="subheading">
                        Software Developer (September 2017 - January 2018)
                      </h6>
                    </div>
                    <div className="timeline-body">
                      <p className="text-muted">
                        I worked as a software developer for 4 months at a
                        Startup called Wherego.
                      </p>
                    </div>
                  </div>
                </li>
                <li className="timeline-inverted">
                  <div className="timeline-image">
                    <img
                      className="rounded-circle img-fluid"
                      src={require(`../softwareAssets/softwareCertificates/brainstation.png`)}
                      alt=""
                    />
                  </div>
                  <div className="timeline-panel">
                    <div className="timeline-heading">
                      <h4>Brainstation </h4>
                      <h6 className="subheading">
                        Web Development Certificate (June 2017 - August 2017)
                      </h6>
                    </div>
                    <div className="timeline-body">
                      <p className="text-muted">
                        I took a 3 months Web Development bootcamp called
                        Brainstation.
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="timeline-image">
                    <img
                      className="rounded-circle img-fluid"
                      src={require(`../softwareAssets/softwareCertificates/uoft.png`)}
                      alt=""
                    />
                  </div>
                  <div className="timeline-panel">
                    <div className="timeline-heading">
                      <h4>University of Toronto</h4>
                      <h6 className="subheading">
                        Bachelor of Applied Science and Engineering (September
                        2011 - June 2016)
                      </h6>
                    </div>
                    <div className="timeline-body">
                      <p className="text-muted">
                        I completed my Bachelor's of Applied Science and
                        Engineering at UofT.
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default SoftwareCertificates;
