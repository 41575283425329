import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  HashRouter,
} from "react-router-dom";
import Software from "./projectDetails/software/Software";
import { Contact } from "./Contact";
import { NoMatch } from "./NoMatch";
import NavigationBar from "./components/NavigationBar";
import HomePage from "./Home_Bootstrap";
import Controls from "./projectDetails/controls/Controls";

function App() {


  return (
    <React.Fragment>
      <HashRouter>
        <NavigationBar />
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route path="/controls" component={Controls} />
          <Route path="/software" component={Software} />
          {/* <Route path="/contact" component={Contact} /> */}
          <Route component={NoMatch} />
        </Switch>
      </HashRouter>
    </React.Fragment>
  );
}

export default App;
