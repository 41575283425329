import React from "react";

function ControlsCertificates() {
  return (
    <div>
      <section className="page-section" id="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="section-heading text-uppercase">
                Skills and Experiences
              </h2>
              <h3 className="section-subheading text-muted">
                A timeline of significant learning milestones on my
                instrumentation & controls engineering journey.
              </h3>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <ul className="timeline">
                <li className="timeline-inverted">
                  <div className="timeline-image">
                    <img
                      className="rounded-circle img-fluid"
                      src={require(`../assets/controlsCertificate/gb.png`)}
                      alt=""
                    />
                  </div>
                  <div className="timeline-panel">
                    <div className="timeline-heading">
                      <h4>George Brown College </h4>
                      <h6 className="subheading">
                        Programmable Logic Controllers Rockwell Certificate
                        (July 2019 - May 2020)
                      </h6>
                    </div>
                    <div className="timeline-body">
                      <p className="text-muted">
                        I completed the Programmable Logic Controllers Rockwell
                        Certification from George Brown College.
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="timeline-image">
                    <img
                      className="rounded-circle img-fluid"
                      src={require(`../assets/controlsCertificate/centennial.png`)}
                      alt=""
                    />
                  </div>
                  <div className="timeline-panel">
                    <div className="timeline-heading">
                      <h4>Centennial College </h4>
                      <h6 className="subheading">
                        Electromechanical Engineering Technology Diploma
                        (September 2019 - April 2020)
                      </h6>
                    </div>
                    <div className="timeline-body">
                      <p className="text-muted">
                        I completed this one year diploma to improve my PLC,
                        maintenance and troubleshooting skills.
                      </p>
                    </div>
                  </div>
                </li>
                <li className="timeline-inverted">
                  <div className="timeline-image">
                    <img
                      className="rounded-circle img-fluid"
                      src={require(`../assets/controlsCertificate/gb.png`)}
                      alt=""
                    />
                  </div>
                  <div className="timeline-panel">
                    <div className="timeline-heading">
                      <h4>George Brown College </h4>
                      <h6 className="subheading">
                        Motion Controls Specialist (July 2019 - January 2020)
                      </h6>
                    </div>
                    <div className="timeline-body">
                      <p className="text-muted">
                        I completed the Motion Controls Specialist Certification
                        from George Brown College.
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="timeline-image">
                    <img
                      className="rounded-circle img-fluid"
                      src={require(`../../software/softwareAssets/softwareCertificates/rbc.jpg`)}
                      alt=""
                    />
                  </div>
                  <div className="timeline-panel">
                    <div className="timeline-heading">
                      <h4>RBC Ventures </h4>
                      <h6 className="subheading">
                        Software Developer (April 2018- September 2019)
                      </h6>
                    </div>
                    <div className="timeline-body">
                      <p className="text-muted">
                        I worked for a year and a half as a full stack software
                        developer at RBC Ventures.
                      </p>
                    </div>
                  </div>
                </li>
                <li className="timeline-inverted">
                  <div className="timeline-image">
                    <img
                      className="rounded-circle img-fluid"
                      src={require(`../assets/controlsCertificate/husky.png`)}
                      alt=""
                    />
                  </div>
                  <div className="timeline-panel">
                    <div className="timeline-heading">
                      <h4>Husky Energy </h4>
                      <h6 className="subheading">
                        Project Management Intern (July 2014 - September 2015)
                      </h6>
                    </div>
                    <div className="timeline-body">
                      <p className="text-muted">
                        As a part of my university's coop program, I worked as a
                        project management intern at Husky Energy.
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="timeline-image">
                    <img
                      className="rounded-circle img-fluid"
                      src={require(`../../software/softwareAssets/softwareCertificates/uoft.png`)}
                      alt=""
                    />
                  </div>
                  <div className="timeline-panel">
                    <div className="timeline-heading">
                      <h4>University of Toronto</h4>
                      <h6 className="subheading">
                        Bachelor of Applied Science and Engineering (September
                        2011 - June 2016)
                      </h6>
                    </div>
                    <div className="timeline-body">
                      <p className="text-muted">
                        I completed my Bachelor's of Applied Science and
                        Engineering at the University of Toronto.
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ControlsCertificates;
